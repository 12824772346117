export enum ContractType {
    Lease = 'Lease',
    LightLeaseSC1 = 'LightLeaseSC1',
    LightLeaseSC2 = 'LightLeaseSC2',
    Loan = 'Loan',
    LightLoanSC1 = 'LightLoanSC1',
    LightLoanSC2 = 'LightLoanSC2',
    BuyInLease = 'BuyInLease',
    IPA = 'IPA',
    BankData = 'BankData',
    DirectPurchase = 'DirectPurchase',
    LightContract = 'LightContract',
}

const CONTRACT_TYPES_BY_ORDER: Record<string, ContractType[]> = {
    PV: [
        ContractType.Lease,
        ContractType.LightLeaseSC1,
        ContractType.LightLeaseSC2,
        ContractType.Loan,
        ContractType.LightLoanSC1,
        ContractType.LightLoanSC2,
        ContractType.BuyInLease,
        ContractType.IPA,
        ContractType.BankData,
        ContractType.DirectPurchase,
        ContractType.LightContract,
    ],
    HP: [ContractType.LightLoanSC1, ContractType.Loan, ContractType.LightContract, ContractType.DirectPurchase],
}

export const ContractType2LabelMapping: Record<ContractType, string> = {
    [ContractType.Lease]: 'Lease',
    [ContractType.LightLeaseSC1]: 'Light Lease SC1',
    [ContractType.LightLeaseSC2]: 'Light Lease SC2',
    [ContractType.Loan]: 'Loan',
    [ContractType.LightLoanSC1]: 'Light Loan SC1',
    [ContractType.LightLoanSC2]: 'Light Loan SC2',
    [ContractType.BuyInLease]: 'Buy In Lease',
    [ContractType.IPA]: 'IPA',
    [ContractType.BankData]: 'Bank Data',
    [ContractType.DirectPurchase]: 'Direct Purchase',
    [ContractType.LightContract]: 'Direct Purchase Light',
}

export function getContractTypesForOrderType(orderType: string): ContractType[] {
    return CONTRACT_TYPES_BY_ORDER[orderType] || []
}
